import { uploadMedia } from "src/ApiConfig/service";
import { Contract } from "@ethersproject/contracts";
import { toast } from "react-hot-toast";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function downloadCsv() {}

export const UpoadCompanyMedia = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  // Apply custom styles to the loading toast (spinner color set to red)
  const id = toast.loading("Uploading media", {
    style: {
      backgroundColor: "transparent", // Optional: background color
      color: "#000", // Toast text color
    },
    icon: (
      <div
        style={{
          border: "2px solid green", // Red spinner color
          borderRadius: "50%",
          borderTop: "2px solid transparent", // Creates a spinning effect
          width: "24px",
          height: "24px",
          animation: "spin 1s linear infinite", // CSS spin animation
        }}
      />
    ),
  });

  const res = await uploadMedia(formData);

  if (res.responceCode === 200) {
    toast.success(res?.responseMessage || "Media uploaded successfully", {
      id: id,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="green"
          viewBox="0 0 24 24"
        >
          <path d="M9 16.2l-3.6-3.6 1.4-1.4L9 13.4l7.6-7.6 1.4 1.4L9 16.2z" />
        </svg>
      ),
    });
    return res?.result;
  } else {
    toast.error(res?.responseMessage || "Unable to upload media", {
      id: id,
    });
  }
};

// Global style to define the spinning animation
const style = document.createElement("style");
style.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);
